import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { Container } from "@/game-lol/components/RunesStatsCard.style.jsx";
import LoLColors from "@/game-lol/constants/colors.mjs";
import { RUNE_STATS } from "@/game-lol/constants/rune-stats.mjs";
import Static from "@/game-lol/utils/static.mjs";
import { getStaticData } from "@/game-lol/utils/util.mjs";
import { classNames } from "@/util/class-names.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useScrollForMore } from "@/util/useScrollForMore.jsx";

type RuneSelection = {
  perk: number;
  var1: number;
  var2: number;
  var3: number;
};
type Tree = {
  description: string;
  selections: RuneSelection[];
};
type PlayerWithRunes = {
  perks: {
    styles: Tree[];
  };
  totalDamageDealtToChampions: number;
  totalHealsOnTeammates: number;
  totalDamageShieldedOnTeammates: number;
};

function RunesStatsCard({
  player,
  maxHeight = 390,
}: {
  player: PlayerWithRunes;
  maxHeight?: number;
}) {
  const { t } = useTranslation();

  const treeStaticData = getStaticData("runes");
  const runeStaticData = getStaticData("runeDetails");

  const {
    perks,
    totalDamageDealtToChampions = 1,
    totalHealsOnTeammates = 1,
    totalDamageShieldedOnTeammates = 1,
  } = player;

  const keystone = perks.styles[0]?.selections[0]?.perk;
  const keystoneImg = keystone && Static.getRuneImage(keystone);

  const primaryTree = (treeStaticData || []).find((tree) => {
    return (tree.slots || []).find((slot) => {
      return (slot.runes || []).find((rune) => rune.id === keystone);
    });
  });
  const secondaryTree = (treeStaticData || []).find((tree) => {
    return (tree.slots || []).find((slot) => {
      return (slot.runes || []).find(
        (rune) =>
          rune.id ===
          perks.styles.find((tree) => tree.description === "subStyle")
            ?.selections[0]?.perk,
      );
    });
  });

  const { containerRef, contentRef, scrollTextComponent } = useScrollForMore();

  return (
    <Card padding="0">
      <div
        ref={containerRef}
        className={Container()}
        style={{ "--base-height": maxHeight && `${maxHeight}px` }}
      >
        <div ref={contentRef} className="content">
          {keystoneImg && (
            <img
              src={keystoneImg}
              width="120"
              height="120"
              className="keystone-bg"
            />
          )}
          {/* <header>
            <h5 className="type-large-title--bold">
              {t("lol:runeStats", "Rune Stats")}
            </h5>
          </header> */}
          {perks.styles.map((tree) => {
            const treeColor =
              tree.description === "primaryStyle"
                ? LoLColors.runes[primaryTree?.key?.toLowerCase()]
                : LoLColors.runes[secondaryTree?.key?.toLowerCase()];
            return (
              <React.Fragment key={tree.description}>
                {tree.selections.map((rune, i) => {
                  if (!RUNE_STATS[rune.perk]) return null;
                  const vals = [rune.var1, rune.var2, rune.var3];
                  const runeDetails = runeStaticData?.[rune.perk];
                  const isKeystone = keystone === rune.perk;

                  return (
                    <div
                      key={`${rune.perk}_${i}`}
                      {...classNames("rune", isKeystone && "keystone")}
                      style={{
                        "--tree-color": treeColor,
                      }}
                    >
                      <div className="rune-meta">
                        <img
                          src={Static.getRuneImage(rune.perk)}
                          height="24"
                          width="25"
                          alt={runeDetails?.name}
                          className="rune-img"
                        />
                        <span className="type-subtitle--bold">
                          {runeDetails?.name || rune.perk}
                        </span>
                      </div>
                      <div className="rune-stats">
                        {RUNE_STATS[rune.perk].map((stat, i) => {
                          const val = stat.valAdjust
                            ? stat.valAdjust(vals[i] || 0)
                            : vals[i] || 0;
                          const override = stat.override && stat.override(vals);
                          const percentOfTotal = stat.damage
                            ? val / totalDamageDealtToChampions
                            : stat.healing
                              ? val / totalHealsOnTeammates
                              : stat.shielding
                                ? val / totalDamageShieldedOnTeammates
                                : 0;
                          return (
                            <div key={`stat_${i}`} className="rune-stat">
                              {stat.label ? (
                                <span className="stat-label type-callout">
                                  {t(...stat.label)}
                                </span>
                              ) : (
                                "-"
                              )}
                              <span>
                                {percentOfTotal ? (
                                  <span className="stat-damage type-callout">
                                    {t(
                                      "common:percentOfTotal",
                                      "{{percent}} of total",
                                      {
                                        percent: percentOfTotal.toLocaleString(
                                          getLocale(),
                                          {
                                            style: "percent",
                                            minimumFractionDigits: 1,
                                            maximumFractionDigits: 1,
                                          },
                                        ),
                                      },
                                    )}
                                  </span>
                                ) : null}{" "}
                                {override || val ? (
                                  <span className="stat-value type-callout--bold">
                                    {override ||
                                      val.toLocaleString(
                                        getLocale(),
                                        stat.format || {},
                                      )}
                                  </span>
                                ) : null}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </React.Fragment>
            );
          })}
        </div>
      </div>
      {scrollTextComponent}
    </Card>
  );
}

export default RunesStatsCard;
