// The purpose of this file is to map rune stats to labels and format the displayed values
// The order of the stats is important because it maps to the supplied order from the API

import { formatDuration } from "@/app/util.mjs";

export const RUNE_STATS = {
  8437: [{ label: ["", "Damage"], damage: true }, { label: ["", "Healing"] }],
  8401: [{ label: ["", "Damage"], damage: true }],
  8429: [
    {
      label: ["", "Percent of game active"],
      format: {
        style: "percent",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      valAdjust: (val) => val / 100,
    },
    { label: ["", "Bonus Armor"] },
    { label: ["", "Bonus Magic Resist"] },
  ],
  8451: [{ label: ["", "Bonus Health"] }],
  8009: [{ label: ["", "Resource Restored"] }],
  8010: [{ label: ["", "Healing"] }],
  9111: [{ label: ["", "Health Restored"] }, { label: ["", "Bonus Gold"] }],
  8014: [{ label: ["", "Damage"], damage: true }],
  8143: [{ label: ["", "Damage"], damage: true }],
  8135: [{ label: ["", "Gold Collected"] }, { label: ["", "Stacks"] }],
  8439: [
    { label: ["", "Damage"], damage: true },
    { label: ["", "Damage Mitigated"] },
  ],
  8463: [{ label: ["", "Ally Healing"] }],
  8005: [
    { label: ["", "Damage"], damage: true },
    { label: ["", "Bonus Damage"] },
    { label: ["", "Expose Damage"], damage: true },
  ],
  9101: [{ label: ["", "Shielding"] }],
  9103: [
    {
      label: ["", "Time completed"],
      override(vals) {
        const seconds = vals[1] < 10 ? `0${vals[1]}` : vals[1];
        return `${vals[0]}:${seconds}`;
      },
    },
  ],
  9104: [
    {
      label: ["", "Time completed"],
      override(vals) {
        const seconds = vals[1] < 10 ? `0${vals[1]}` : vals[1];
        return `${vals[0]}:${seconds}`;
      },
    },
  ],
  9105: [
    {
      label: ["", "Time completed"],
      override(vals) {
        const seconds = vals[1] < 10 ? `0${vals[1]}` : vals[1];
        return `${vals[0]}:${seconds}`;
      },
    },
  ],
  8017: [{ label: ["", "Damage"], damage: true }],
  8126: [{ label: ["", "Damage"], damage: true }],
  8138: [{ label: ["", "Bonus AD/AP"] }],
  8139: [{ label: ["", "Healing"] }],
  8008: [
    {
      label: ["", "Max Stacks Uptime"],
      valAdjust(val) {
        const format = val >= 600 ? "mm:ss" : "m:ss";
        return formatDuration(val * 1000, format);
      },
    },
  ],
  8214: [
    { label: ["", "Damage Dealt"], damage: true },
    { label: ["", "Damage Shielded"] },
  ],
  8226: [{ label: ["", "Bonus Mana"] }, { label: ["", "Mana Restored"] }],
  8210: [{ label: ["", "Seconds Refunded"] }],
  8237: [{ label: ["", "Damage"], damage: true }],
  8360: [{ label: ["", "Summoners Swapped"] }],
  8304: [
    {
      label: ["", "Time Completed"],
      override(vals) {
        return `${vals[0]}:${vals[1]}${vals[2]}`;
      },
    },
  ],
  8321: [{ label: ["", "Future Purchases"] }],
  8453: [{ label: ["", "Healing"] }, { label: ["", "Shielding"] }],
  8444: [{ label: ["", "Healing"] }],
  8347: [],
  8351: [
    {
      label: ["", "Time Slowed"],
      valAdjust(val) {
        const format = val >= 600 ? "mm:ss" : "m:ss";
        return formatDuration(val * 1000, format);
      },
    },
    { label: ["", "Damage Reduced"] },
  ],
  8345: [{ label: ["", "Biscuits Received"] }],
  8369: [
    { label: ["", "Damage"], damage: true },
    { label: ["", "Gold Gained"] },
  ],
  8313: [{ label: ["", "Tonics Gained"] }],
  8316: [{ label: ["", "Bonus Minion Damage"] }],
  8352: [
    {
      label: ["", "Time Hasted"],
      valAdjust(val) {
        const format = val >= 600 ? "mm:ss" : "m:ss";
        return formatDuration(val * 1000, format);
      },
    },
    { label: ["", "Health Restored"] },
    { label: ["", "Mana Restored"] },
  ],
  8446: [{ label: ["", "Tower Damage"] }],
  8473: [{ label: ["", "Damage Blocked"] }],
  8021: [{ label: ["", "Healing"] }],
  8229: [{ label: ["", "Damage"], damage: true }],
  8230: [{ label: ["", "Activations"] }],
  8236: [{ label: ["", "Bonus AD/AP"] }],
  8128: [{ label: ["", "Damage"], damage: true }, { label: ["", "Stacks"] }],
  8106: [{ label: ["", "Stacks"] }],
  9923: [
    { label: ["", "Total Attacks"] },
    {
      label: ["", "Attacks Landed"],
      format: {
        style: "percent",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      valAdjust: (val) => val / 100,
    },
  ],
  8112: [{ label: ["", "Damage"], damage: true }],
  8465: [{ label: ["", "Shielding"] }],
  8306: [{ label: ["", "Times Used"] }],
  8410: [{ label: ["", "Seconds Hasted"] }],
  8124: [{ label: ["", "Damage"], damage: true }],
  8299: [{ label: ["", "Damage"], damage: true }],
  8134: [{ label: ["", "Item Activations"] }, { label: ["", "Stacks"] }],
  8105: [
    {
      label: ["", "Movement Speed"],
      format: {
        style: "percent",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      valAdjust: (val) => val / 100,
    },
  ],
  8234: [{ label: ["", "Extra Distance"] }],
  8224: [{ label: ["", "Shielding"] }],
  8242: [
    {
      label: ["", "Time w/ Bonus Resist"],
      valAdjust(val) {
        const format = val >= 600 ? "mm:ss" : "m:ss";
        return formatDuration(val * 1000, format);
      },
    },
  ],
  8275: [{ label: ["", "Activations"] }],
};
