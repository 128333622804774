import { css } from "goober";

export const Container = () => css`
  height: var(--base-height);

  &::-webkit-scrollbar {
    width: 0;
  }

  > * {
    position: relative;
  }

  .content {
    padding-block: var(--sp-4);
  }
  header {
    position: relative;
    padding: var(--sp-6) var(--sp-6) 0;

    > * {
      position: relative;
    }
  }
  .rune {
    display: grid;
    gap: var(--sp-1);
    padding: var(--sp-3) var(--sp-6);

    &.keystone {
      .rune-meta {
        gap: var(--sp-1);
        translate: -0.5rem 0;
      }
      .rune-img {
        width: var(--sp-10);
        height: var(--sp-10);
        border-radius: 0;
        border: none;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--card-inset-border);
    }
  }
  .rune-meta {
    display: flex;
    align-items: center;
    gap: var(--sp-2);
    color: var(--tree-color);
  }
  .rune-img {
    border-radius: 50%;
    border: 2px solid var(--tree-color, var(--shade6));
  }
  .rune-stats {
  }
  .rune-stat {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
  .stat-value {
    color: var(--tree-color);
  }
  .stat-damage {
    color: var(--shade0-50);
    padding-inline-end: var(--sp-1);
  }
  .keystone-bg {
    position: absolute;
    top: -7rem;
    right: -10rem;
    width: 30rem;
    height: 30rem;
    filter: blur(12px);
    -webkit-mask-image: linear-gradient(to top, transparent 25%, black);
    opacity: 0.25;
    pointer-events: none;
  }
`;
