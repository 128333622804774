import React, { memo, useState } from "react";
import { flushSync } from "react-dom";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card";

import { readState } from "@/__main__/app-state.mjs";
import MatchBans from "@/game-lol/components/MatchBans.jsx";
import GoldGraph from "@/game-lol/components/MatchGoldGraph.jsx";
import MatchScoreboard from "@/game-lol/components/MatchScoreboard.jsx";
import MatchStats from "@/game-lol/components/MatchStats.jsx";
import { containerCSS } from "@/game-lol/components/MatchTabs.style.jsx";
import PostMatchTimeline from "@/game-lol/components/PostmatchTimeline.jsx";
import WinProbability from "@/game-lol/components/WinProbabilityChart.jsx";
import { isSRCoachableQueue } from "@/game-lol/utils/coaching-utils.mjs";
import { classNames } from "@/util/class-names.mjs";
import globals from "@/util/global-whitelist.mjs";
import { sendInteractionEvent } from "@/util/use-interaction-event.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function isWithinTwoWeeks(dateToCheck) {
  const twoWeeksInMs = 14 * 24 * 60 * 60 * 1000; // 14 days in milliseconds
  const now = new Date();
  const checkDate = new Date(dateToCheck);
  const difference = Math.abs(now - checkDate);
  return difference <= twoWeeksInMs;
}

const MatchTabs = ({ region, matchId, localPlayer, profile, playerScores }) => {
  const { t } = useTranslation();
  const {
    lol: { matches, matchTimeline },
  } = useSnapshot(readState);
  const matchKey = `${region?.toUpperCase()}_${matchId}`;
  const matchData = matches?.[matchKey];
  const timelineData = matchTimeline?.[matchId];

  const tabs = [
    {
      label: ["lol:postmatch.tabViewHeaders.overview", "Overview"],
      component: (
        <MatchScoreboard
          region={region}
          matchId={matchId}
          localPlayer={localPlayer}
          playerScores={playerScores}
        />
      ),
    },
    {
      label: ["common:stats", "Stats"],
      component: (
        <MatchStats region={region} matchId={matchId} profile={profile} />
      ),
    },
  ];

  const [activeTab, setActiveTab] = useState(0);

  if (timelineData) {
    tabs.push({
      label: ["lol:postmatch.tabViewHeaders.timeline", "Timeline"],
      component: (
        <PostMatchTimeline
          timeline={timelineData}
          match={matchData}
          currParticipant={localPlayer}
        />
      ),
    });
    tabs.push({
      label: ["lol:postmatch.tabViewHeaders.goldGraph", "Gold Graph"],
      component: (
        <GoldGraph
          region={region}
          matchId={matchId}
          profile={profile}
          currParticipant={localPlayer}
        />
      ),
    });

    if (isSRCoachableQueue(matchData)) {
      tabs.push({
        label: ["common:winProbability", "Win Probability"],
        dateImplemented: "2025-01-30",
        component: (
          <WinProbability
            region={region}
            matchId={matchId}
            profile={profile}
            currParticipant={localPlayer}
          />
        ),
      });
    }
  }

  function handleClick(index, tab) {
    if ("startViewTransition" in globals.document) {
      globals.document.startViewTransition(() => {
        flushSync(() => {
          setActiveTab(index);
          sendInteractionEvent("postmatch-tabs", {
            tab: tab.label[1],
          });
        });
      });
      return;
    }

    setActiveTab(index);
    sendInteractionEvent("postmatch-tabs", {
      tab: tab.label[1],
    });
  }

  return (
    <Card
      className={containerCSS()}
      padding={0}
      style={{ viewTransitionName: "match-tabs" }}
    >
      <header className="tabs-header">
        <nav className="tabs-list type-subtitle--semi">
          {tabs.map((tab, index) => {
            const isNew =
              tab.dateImplemented && isWithinTwoWeeks(tab.dateImplemented);

            return (
              <button
                key={index}
                onClick={() => handleClick(index, tab)}
                {...classNames("tabs-tab", activeTab === index && "active")}
                data-new={isNew ? t("common:new", "New") : null}
              >
                {t(...tab.label)}
              </button>
            );
          })}
        </nav>
        <MatchBans region={region} matchId={matchId} />
      </header>
      <div className="tabs-content">
        {tabs[activeTab]?.component || tabs[0].component}
      </div>
    </Card>
  );
};

export default memo(MatchTabs);
