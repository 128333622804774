import { css } from "goober";

export const containerCSS = () => css`
  container-type: inline-size;
  container-name: stats;
  display: grid;
  gap: var(--sp-2);

  .section-header {
    background: var(--shade7);
    color: var(--shade2);
  }

  .player-champion,
  .player-champion img {
    --radii: 50%;
    inline-size: var(--sp-6);
    block-size: var(--sp-6);
    border-radius: var(--radii);
  }

  .section-row--container {
    container-type: inline-size;
    container-name: stats-row;

    &:not(:last-child) {
      border-bottom: 1px solid var(--card-inset-border);
    }
  }
  .section-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--sp-1_5) var(--sp-3);

    @container stats-row (max-inline-size: 550px) {
      & {
        flex-wrap: wrap;
      }
    }
  }
  .section-row--title {
    inline-size: 20ch;
    flex-shrink: 0;
    color: var(--shade1);

    span {
      position: sticky;
      left: var(--sp-3);
    }

    @container stats-row (max-inline-size: 800px) {
      & {
        inline-size: 12ch;
      }
      &.stats {
        inline-size: 100%;
      }
    }
    @container stats-row (max-inline-size: 700px) {
      & {
        inline-size: 7ch;
      }
      &.stats {
        inline-size: 100%;
      }
    }
    @container stats-row (max-inline-size: 550px) {
      & {
        inline-size: 100%;
      }
    }
  }
  .section-header .section-row--title {
    color: var(--shade0);
  }
  .section-stat {
    flex: 1;
    display: flex;
    justify-content: center;
    color: var(--stat-color, var(--shade1));
    padding-inline: var(--sp-1);

    .fb-dot {
      background: var(--dot-color);
      height: var(--sp-1_5);
      width: var(--sp-1_5);
      border-radius: 50%;
    }
  }
`;
