import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { containerCSS } from "@/game-lol/components/MatchStats.style.jsx";
import Static from "@/game-lol/utils/static.mjs";
import {
  getDerivedRiotID,
  isArenaQueue,
  isSameAccount,
  kFormatter,
} from "@/game-lol/utils/util.mjs";
import { classNames } from "@/util/class-names.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function MatchStats({ region, matchId, profile }) {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const match = state.lol.matches[`${region?.toUpperCase()}_${matchId}`];

  // Todo: placeholder for no data
  if (!match) return null;

  const isArena = isArenaQueue(match.queueId);

  const teams = !isArena
    ? match.teams.map((team) => {
        const players = match.participants.filter(
          (player) => player.teamId === team.teamId,
        );
        return {
          ...team,
          players,
        };
      })
    : match.participants
        .filter(
          (p, i) =>
            match.participants.findIndex(
              (p2) => p2.playerSubteamId === p.playerSubteamId,
            ) === i,
        )
        .map((p) => {
          const players = match.participants.filter(
            (p2) => p2.playerSubteamId === p.playerSubteamId,
          );
          return {
            subteamPlacement: p.subteamPlacement,
            players,
          };
        })
        .sort((a, b) => a.subteamPlacement - b.subteamPlacement);

  const participants = teams.reduce(
    (acc, curr) => acc.concat(curr.players),
    [],
  );
  const yourIndex = participants.findIndex(
    (player) =>
      (profile?.riotAccount &&
        isSameAccount(player, {
          summonerName:
            profile.riotAccount?.gameName &&
            profile.riotAccount?.tagLine &&
            getDerivedRiotID(
              profile.riotAccount?.gameName,
              profile.riotAccount?.tagLine,
            ),
        })) ||
      isSameAccount(player, {
        summonerName: profile?.summonerName,
      }),
  );
  const KDAs = [],
    largestKillingSprees = [],
    largestMultiKills = [],
    crowdControlScores = [],
    totalDamageDealtToChampions = [],
    physicalDamageDealtToChampions = [],
    magicDamageDealtToChampions = [],
    trueDamageDealtToChampions = [],
    totalDamageDealts = [],
    physicalDamageDealts = [],
    magicDamageDealts = [],
    trueDamageDealts = [],
    largestCriticalStrikes = [],
    damageDealtToObjectives = [],
    damageDealtToTurrets = [],
    totalHeal = [],
    allyHealing = [],
    allyShielding = [],
    totalDamageTaken = [],
    physicalDamageTaken = [],
    magicDamageTaken = [],
    trueDamageTaken = [],
    damageSelfMitigated = [],
    visionScore = [],
    wardsPlaced = [],
    wardsKilled = [],
    visionWardsBoughtInGame = [],
    goldEarned = [],
    goldSpent = [],
    totalMinionsKilled = [],
    neutralMinionsKilled = [],
    turretKills = [],
    inhibitorKills = [];

  for (const participant of participants) {
    KDAs.push(
      `${participant.kills}/${participant.deaths}/${participant.assists}`,
    );
    largestKillingSprees.push(participant.largestKillingSpree);
    largestMultiKills.push(participant.largestMultiKill);
    crowdControlScores.push(participant.timeCCingOthers);
    totalDamageDealtToChampions.push(
      kFormatter(participant.totalDamageDealtToChampions),
    );
    physicalDamageDealtToChampions.push(
      kFormatter(participant.physicalDamageDealtToChampions),
    );
    magicDamageDealtToChampions.push(
      kFormatter(participant.magicDamageDealtToChampions),
    );
    trueDamageDealtToChampions.push(
      kFormatter(participant.trueDamageDealtToChampions),
    );
    totalDamageDealts.push(kFormatter(participant.totalDamageDealt));
    physicalDamageDealts.push(kFormatter(participant.physicalDamageDealt));
    magicDamageDealts.push(kFormatter(participant.magicDamageDealt));
    trueDamageDealts.push(kFormatter(participant.trueDamageDealt));
    largestCriticalStrikes.push(kFormatter(participant.largestCriticalStrike));
    damageDealtToObjectives.push(
      kFormatter(participant.damageDealtToObjectives),
    );
    damageDealtToTurrets.push(kFormatter(participant.damageDealtToTurrets));
    totalHeal.push(kFormatter(participant.totalHeal));
    allyHealing.push(kFormatter(participant.totalHealsOnTeammates));
    allyShielding.push(kFormatter(participant.totalDamageShieldedOnTeammates));
    totalDamageTaken.push(kFormatter(participant.totalDamageTaken));
    physicalDamageTaken.push(kFormatter(participant.physicalDamageTaken));
    magicDamageTaken.push(kFormatter(participant.magicDamageTaken));
    trueDamageTaken.push(kFormatter(participant.trueDamageTaken));
    damageSelfMitigated.push(kFormatter(participant.damageSelfMitigated));
    visionScore.push(kFormatter(participant.visionScore));
    wardsPlaced.push(kFormatter(participant.wardsPlaced));
    wardsKilled.push(kFormatter(participant.wardsKilled));
    visionWardsBoughtInGame.push(
      kFormatter(participant.visionWardsBoughtInGame),
    );
    goldEarned.push(kFormatter(participant.goldEarned));
    goldSpent.push(kFormatter(participant.goldSpent));
    totalMinionsKilled.push(kFormatter(participant.totalMinionsKilled));
    neutralMinionsKilled.push(kFormatter(participant.neutralMinionsKilled));
    turretKills.push(kFormatter(participant.turretKills));
    inhibitorKills.push(kFormatter(participant.inhibitorKills));
  }

  const combat = [
    {
      header: t("common:stats.kda", "KDA"),
      data: KDAs,
      kda: true,
    },
    {
      header: t(
        "lol:postmatch.advancedStatsSubHeaders.largestKillingSpree",
        "Largest Killing Spree",
      ),
      data: largestKillingSprees,
    },
    {
      header: t(
        "lol:postmatch.advancedStatsSubHeaders.largestMultiKill",
        "Largest Multi Kill",
      ),
      data: largestMultiKills,
    },
    {
      header: t(
        "lol:postmatch.advancedStatsSubHeaders.crowdControlScore",
        "Crowd Control Score",
      ),
      data: crowdControlScores,
    },
  ];
  const damage = [
    {
      header: t(
        "lol:postmatch.advancedStatsSubHeaders.totalDmgToChampions",
        "Total Dmg To Champions",
      ),
      data: totalDamageDealtToChampions,
    },
    {
      header: t(
        "lol:postmatch.advancedStatsSubHeaders.physicalDmgToChampions",
        "Physical Dmg To Champions",
      ),
      data: physicalDamageDealtToChampions,
    },
    {
      header: t(
        "lol:postmatch.advancedStatsSubHeaders.magicDmgToChampions",
        "Magic Dmg To Champions",
      ),
      data: magicDamageDealtToChampions,
    },
    {
      header: t(
        "lol:postmatch.advancedStatsSubHeaders.trueDmgToChampions",
        "True Dmg To Champions",
      ),
      data: trueDamageDealtToChampions,
    },
    {
      header: t("lol:postmatch.advancedStatsSubHeaders.totalDmg", "Total Dmg"),
      data: totalDamageDealts,
    },
    {
      header: t(
        "lol:postmatch.advancedStatsSubHeaders.physicalDmg",
        "Physical Dmg",
      ),
      data: physicalDamageDealts,
    },
    {
      header: t("lol:postmatch.advancedStatsSubHeaders.magicDmg", "Magic Dmg"),
      data: magicDamageDealts,
    },
    {
      header: t("lol:postmatch.advancedStatsSubHeaders.trueDmg", "True Dmg"),
      data: trueDamageDealts,
    },
    {
      header: t(
        "lol:postmatch.advancedStatsSubHeaders.largestCriticalStrike",
        "Largest Critical Strike",
      ),
      data: largestCriticalStrikes,
    },
    {
      header: t(
        "lol:postmatch.advancedStatsSubHeaders.totalDmgToObjectives",
        "Total Dmg To Objectives",
      ),
      data: damageDealtToObjectives,
    },
    {
      header: t(
        "lol:postmatch.advancedStatsSubHeaders.totalDmgtoTurrets",
        "Total Dmg to Turrets",
      ),
      data: damageDealtToTurrets,
    },
  ];
  const damageTaken = [
    {
      header: t(
        "lol:postmatch.advancedStatsSubHeaders.dmgHealed",
        "Dmg Healed",
      ),
      data: totalHeal,
    },
    {
      header: t(
        "lol:postmatch.advancedStatsSubHeaders.allyHealing",
        "Ally Healing",
      ),
      data: allyHealing,
    },
    {
      header: t(
        "lol:postmatch.advancedStatsSubHeaders.allyShielding",
        "Ally Shielding",
      ),
      data: allyShielding,
    },
    {
      header: t("lol:postmatch.advancedStatsSubHeaders.dmgTaken", "Dmg Taken"),
      data: totalDamageTaken,
    },
    {
      header: t(
        "lol:postmatch.advancedStatsSubHeaders.physicalDmgTaken",
        "Physical Dmg Taken",
      ),
      data: physicalDamageTaken,
    },
    {
      header: t(
        "lol:postmatch.advancedStatsSubHeaders.magicDmgTaken",
        "Magic Dmg Taken",
      ),
      data: magicDamageTaken,
    },
    {
      header: t(
        "lol:postmatch.advancedStatsSubHeaders.trueDmgTaken",
        "True Dmg Taken",
      ),
      data: trueDamageTaken,
    },
    {
      header: t(
        "lol:postmatch.advancedStatsSubHeaders.selfMitigatedDmg",
        "Self Mitigated Dmg",
      ),
      data: damageSelfMitigated,
    },
  ];
  const vision = [
    {
      header: t(
        "lol:postmatch.advancedStatsSubHeaders.visionScore",
        "Vision Score",
      ),
      data: visionScore,
    },
    {
      header: t(
        "lol:postmatch.advancedStatsSubHeaders.wardsPlaced",
        "Wards Placed",
      ),
      data: wardsPlaced,
    },
    {
      header: t(
        "lol:postmatch.advancedStatsSubHeaders.wardsDestroyed",
        "Wards Destroyed",
      ),
      data: wardsKilled,
    },
    {
      header: t(
        "lol:postmatch.advancedStatsSubHeaders.controlWardsPurchased",
        "Control Wards Purchased",
      ),
      data: visionWardsBoughtInGame,
    },
  ];
  const income = [
    {
      header: t(
        "lol:postmatch.advancedStatsSubHeaders.goldEarned",
        "Gold Earned",
      ),
      data: goldEarned,
    },
    {
      header: t(
        "lol:postmatch.advancedStatsSubHeaders.goldSpent",
        "Gold Spent",
      ),
      data: goldSpent,
    },
    {
      header: t(
        "lol:postmatch.advancedStatsSubHeaders.minionsKilled",
        "Minions Killed",
      ),
      data: totalMinionsKilled,
    },
    {
      header: t(
        "lol:postmatch.advancedStatsSubHeaders.neutralMinionsKilled",
        "Neutral Minions Killed",
      ),
      data: neutralMinionsKilled,
    },
  ];
  const misc = [
    {
      header: t(
        "lol:postmatch.advancedStatsSubHeaders.towersDestroyed",
        "Towers Destroyed",
      ),
      data: turretKills,
    },
  ];

  if (!inhibitorKills.includes(null)) {
    misc.push({
      header: t(
        "lol:postmatch.advancedStatsSubHeaders.inhibitorsDestroyed",
        "Inhibitors Destroyed",
      ),
      data: inhibitorKills,
    });
  }

  const sections = [
    {
      header: t("lol:postmatch.advancedStatsHeaders.combat", "Combat"),
      data: combat,
    },
    {
      header: t(
        "lol:postmatch.advancedStatsHeaders.damageDealt",
        "Damage Dealt",
      ),
      data: damage,
    },
    {
      header: t(
        "lol:postmatch.advancedStatsHeaders.damageTakenAndHealed",
        "Damage Taken and Healed",
      ),
      data: damageTaken,
    },
    {
      header: t("lol:postmatch.advancedStatsHeaders.vision", "Vision"),
      data: vision,
    },
    {
      header: t("lol:postmatch.advancedStatsHeaders.income", "Income"),
      data: income,
    },
    {
      header: t("lol:postmatch.advancedStatsHeaders.objectives", "Objectives"),
      data: misc,
    },
  ];

  return (
    <div className={containerCSS()}>
      {sections.map((section, i) => {
        return (
          <Card key={section.header} padding="0" className="section">
            <header className="section-row--container">
              <div className="section-row section-header">
                <p
                  {...classNames(
                    "section-row--title",
                    "type-caption--bold",
                    i !== 0 && "stats",
                  )}
                >
                  <span>{section.header}</span>
                </p>
                {i === 0 &&
                  participants.map((player) => {
                    return (
                      <div
                        key={player.puuid}
                        className="section-stat player-champion"
                      >
                        <img
                          src={Static.getChampionImage(player.championId)}
                          width="24"
                          height="24"
                          loading="lazy"
                        />
                      </div>
                    );
                  })}
              </div>
            </header>
            <div className="section-stats">
              {section.data.map((row) => {
                return (
                  <div key={row.header} className="section-row--container">
                    <div className="section-row">
                      <p className="section-row--title type-caption--bold">
                        <span>{row.header}</span>
                      </p>
                      {row.data?.map((value, i) => {
                        const isUser = yourIndex === i;
                        const valueColor = isUser
                          ? "var(--yellow)"
                          : "var(--shade2)";

                        return (
                          <div
                            key={i}
                            className="section-stat type-caption"
                            style={{ "--stat-color": valueColor }}
                          >
                            {value}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </Card>
        );
      })}
    </div>
  );
}

export default memo(MatchStats);
